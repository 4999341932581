
import { Component, Vue } from 'vue-property-decorator';
import { SignInResponse } from '@/models/SignIn';
import URLSearchParams from 'url-search-params';
import UserModule from '@/store/modules/UserModule';
import Alert from '../utils/Alert.vue';
import LoginResetPasswordForm from './LoginResetPasswordForm.vue';
import LoginCartActions from './LoginCartActions.vue';
import TrackingService from '@/services/TrackingService';

const trackingService: TrackingService = new TrackingService();

@Component({
    name: 'LoginForm',
    components: {
        Alert,
        LoginResetPasswordForm,
        LoginCartActions,
    },
})
export default class LoginForm extends Vue {
    private urlParams: URLSearchParams = null;
    private loading: boolean = false;
    private username: string = '';
    private password: string = '';
    private remember: boolean = false;
    private modals: any = {
        resetPassword: false,
        cartActions: false,
    };
    private alerts: any = {
        invalid: false,
        critical: false,
    };

    private created() {
        this.urlParams = new URLSearchParams(window.location.search);
        if (this.urlParams.get('signinerror')) {
            if (this.urlParams.get('errortype') === '0') {
                this.alerts.invalid = true;
            }
            if (this.urlParams.get('errortype') === '1') {
                this.alerts.critical = true;
            }
        }
    }

    private async login() {
        if (this.loading) return;
        this.alerts = {
            invalid: false,
            critical: false,
        };
        this.loading = true;
        const signIn: SignInResponse = await UserModule.SIGN_IN({
            username: this.username,
            password: this.password,
            remember: this.remember
        });
        if (signIn.signincompleted) {
            await trackingService.trackLogin(this.username);
            
            if (signIn.previouscartfound) {
                this.modals.cartActions = true;
            } else {
                window.location.reload();
            }
        } else {
            if (signIn.errortype === 0) {
                this.alerts.invalid = true;
            } else if (signIn.errortype === 1) {
                this.alerts.critical = true;
            }
        }
        this.loading = false;
    }

    private modalShow(section: string) {
        this.alerts = {
            invalid: false,
            critical: false,
        };
        this.modals[section] = true;
    }

    private modalHide(section: string) {
        if (section === 'cartActions') {
            window.location.reload();
        } else {
            this.modals[section] = false;
        }
    }
}


import UserModule from '@/store/modules/UserModule';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { StockMessage } from '../models/Product';

@Component({
    name: 'AddToCartStock'
})
export default class AddToCartStock extends Vue {
    @Prop({ type: Object, required: false, default: null }) private stockMessage: StockMessage;
    @Prop({ type: Boolean, required: false, default: false }) private inline: boolean;
    @Prop({ type: String, required: false, default: 'top' }) private position: boolean;

    get isSalesrep(): boolean {
        return UserModule.USER_ROLE === 'Salesperson' || UserModule.USER_ROLE === 'Supervisor';
    }

    get showStock(): boolean {
        return this.stockMessage != null;
    }

    get icon(): string {
        return this.stockMessage?.icon;
    }

    get color(): string {
        return this.stockMessage?.color;
    }

    get message(): string {
        if (!this.stockMessage?.instock)
            return this.stockMessage.restockmessage ?? this.stockMessage.message;

        return this.stockMessage.message;
    }

    get deliveryMessage(): string {
        if (!this.stockMessage?.showdeliverymessageonlists) return null;

        return this.stockMessage?.deliverymessage;
    }
}
